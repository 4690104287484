<template>
  <section id="password_reset" class="bg-gray-100 h-full p-5">
    <tw-container>
      <form
        v-if="!message"
        class="px-8 pt-6 pb-8 max-w-lg"
        @submit.prevent.stop="submit()"
      >
        <h1 class="text-3xl font-primary uppercase font-bold text-secondary">Restaurar Contraseña</h1>
        <p v-if="errors" class="text-red-500">
          <ul>
            <li v-for="(error, index) in errors.errors.password" :key="`password-error-${index}`">
              {{ error }}
            </li>
            <li v-for="(error, index) in errors.errors.email" :key="`email-error-${index}`">
              {{ error }}
            </li>
          </ul>
        </p>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Contraseña nueva
          </label>
          <input
            id="password"
            v-model="form.password"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            placeholder="********"
            required
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Confirmar nueva contraseña
          </label>
          <input
            id="password_confirmation"
            v-model="form.password_confirmation"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password_confirmation"
            placeholder="********"
            required
          />
        </div>
        <div class="">
          <promise-button type="submit" class="btn-secondary" :promise="promise" disable-on-promise>
            <template #default>
              Restaurar
            </template>
            <template #fallback>
              Restaurando...
            </template>
          </promise-button>
        </div>
      </form>
      <div v-else class="my-24 grid place-content-center">
        <span class="font-secondary text-secondary font-bold text-4xl uppercase py-3">
          {{ message }}
        </span>
        <tw-button :to="{name: 'Home'}" class="bg-primary hover:bg-secondary text-white block uppercase">
          Pagina Principal
        </tw-button>
      </div>
    </tw-container>
  </section>
</template>

<script lang="ts">
import { ref } from '@vue/reactivity';
import { defineComponent } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useErrors, resetPassword } from '../store/security';

interface Form {
  password: string | null;
  password_confirmation: string | null;
}

export default defineComponent({
  props: {
    token: String,
  },
  setup(props) {
    const route = useRoute();
    const errors = useErrors();
    const form = ref<Form>({
      password: null,
      password_confirmation: null,
    });
    const promise = ref<Promise<any>|null>(null);
    const message = ref<any>(null);

    const submit = () => {
      promise.value = resetPassword({
        ...form.value,
        email: route.query.email as string,
        token: props.token,
      }).then(response => {
        if(response.status === 200) {
          message.value = response.data.message;
        }
      }).finally(() => {
        promise.value = null;
      });
    };

    return { errors, form, message, promise, submit };
  },
});
</script>

<style></style>
