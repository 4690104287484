
import { ref } from '@vue/reactivity';
import { defineComponent } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useErrors, resetPassword } from '../store/security';

interface Form {
  password: string | null;
  password_confirmation: string | null;
}

export default defineComponent({
  props: {
    token: String,
  },
  setup(props) {
    const route = useRoute();
    const errors = useErrors();
    const form = ref<Form>({
      password: null,
      password_confirmation: null,
    });
    const promise = ref<Promise<any>|null>(null);
    const message = ref<any>(null);

    const submit = () => {
      promise.value = resetPassword({
        ...form.value,
        email: route.query.email as string,
        token: props.token,
      }).then(response => {
        if(response.status === 200) {
          message.value = response.data.message;
        }
      }).finally(() => {
        promise.value = null;
      });
    };

    return { errors, form, message, promise, submit };
  },
});
